.redirectLoaderContainer {
    font-size: 16px;
    margin: 0 20px;
}
.slideCustomTitle {
  :global {
    .ui.header>.image:not(.icon), .ui.header > img {
      width: 21px;
    }
  }
}