.loadingSecond {
    background-image: url(https://static.bankbazaar.com/images/sprite/eform-ring-loader.gif);
    background-repeat: no-repeat;
    background-position: 0 0;
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 29px;
    text-align: center;
    color: #3d6984;
}