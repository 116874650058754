.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;

  &.in {
    opacity: 1;
  }
}

@-webkit-keyframes progressBarStripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}

@keyframes progressBarStripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}

@mixin gradientStriped($color: rgba(255, 255, 255, 0.15), $angle: 45deg) {
  background-image: -webkit-linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent) !important;
  background-image: -o-linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent) !important;
  background-image: linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent) !important;
  background-image: -moz-linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent) !important;
}

@mixin animation($animation) {
  -webkit-animation: $animation !important;
  -o-animation: $animation !important;
  -moz-animation: $animation !important;
  -ms-animation: $animation !important;
  animation: $animation !important;
}

.progressBarStriped {
  @include gradientStriped();
  background-size: 40px 40px !important;
}

.progressBarStripedActive,
.progressBarStripedActive a {
  @extend .progressBarStriped;
  @include animation(progressBarStripes 2s linear infinite);
  cursor: default !important;
}