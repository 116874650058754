.searchLoader {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    background: white;
    padding: 40px 0 20px;
    img {
        border-radius: 6px;
    }
    p {
        margin: 10px auto;
        font-size: 18px;
    }
}